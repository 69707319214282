import React from "react"

const WebDevelopmentPage = () => {
	return (

		<section>
			<div className="parallax">
				<img src="/img/working-1.jpeg" alt="" />
				<div className="overlay"></div>
				<div className="headercontent">
					<h1>PRODUCTS</h1>
				</div>
			</div>
		</section>

	)
}

export default WebDevelopmentPage
