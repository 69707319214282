import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductsIndex from "../components/products/index"
const ProductsPage = () => {
    return (
        <Layout>
            <SEO
                title="Foot Operated Hand Free Sanitizer Dispenser Stand in India - Innoway Systems"
                desc="Our Best Selling Products Foot Operated Hand Free Sanitizer Dispenser Stand"
                pathname="/products/"
                keywords="work stations,Hand Sanitizer in India,Hand Sanitizer in coimbatore"
            />
            <ProductsIndex />
        </Layout>
    )
}

export default ProductsPage
