import React from "react"
import Slider from "./slider"
import OurBest from "./ourbest"
import Need from "./need"
const ProductsPage = () => {
    return (
        <>

            <Slider />
            <OurBest />
            <Need />

        </>
    )
}

export default ProductsPage
