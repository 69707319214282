import React from "react"
import { Link } from "gatsby"

const need = () => {
        return (
                <>
                        <section className="world">
                                <div className="container pt-4">

                                        <div className="row">
                                                <div className="col-12 col-lg-9 text-center">
                                                        <h3>Need to talk with us before sending enquiry? We are available 24 x 7.</h3>
                                                </div>

                                                <div className="col-12 col-lg-3 text-left">
                                                        <Link to="/contactus/" className="btn btn-danger" >Contact Now</Link>

                                                </div>
                                        </div>
                                </div><br />
                        </section>
                </>
        )
}

export default need
