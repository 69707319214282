import React from "react"
import { Link } from "gatsby"



const ourbest = () => {
    return (
        <>
            <section className="products-card">
                <div className="container">
                    <h2 className="text-center font-weight-bold">Our Best Selling Products</h2><br />
                    <div className="row boder">

                        <div className="col-12 col-lg-3 text-center">
                            <h4 className="center">Foot Operated Hand Free Sanitizer Dispenser Stand</h4>
                            <img src="/img/WhatsApp_Image_2020-06-08_at_9-removebg-preview-1-137x300.png" alt="Sanitizer "></img>
                            <a href="/documents/Handfree-Sanitizer.pdf" target="_blank" className="btn btn-danger" >View/Download PDF</a><br /><br />
                        </div>

                        <div className="col-12 col-lg-1">
                            <center>  <div className="vl"></div></center>

                        </div>

                        <div className="col-12 col-lg-3 text-left "><br /><br /><br /><br />
                            <p><strong>Products Specifications:</strong></p>
                            <p><span> Height : 1180 mm</span><span><br />L x B : 210 mm x 216 mm</span><span><br />Weight : 4.100 Kg</span><span><br />Coating : Powder coated</span></p>
                        </div>

                        <div className="col-12 col-lg-4 "><br /><br /><br /><br />
                            <p>
                                Price: Rs. 1450 / item(including shipment charge only for TamilNadu, other states may vary)
                                Shipping:inclusive
                                Delivery:7 – 10 days
Payment Mode:NEFT/VISA/Master/<br /><br />


                                <Link to="/contactus/" className="btn btn-danger" >Contact Now</Link>
                            </p>
                        </div>




                    </div>

                </div>
            </section>

        </>
    )
}

export default ourbest
